<div style="max-width: 900px">
  <div class="header">
    <h2>
      {{ "Filters" | translate }}
    </h2>
    <span>
      {{
        "PleaseSelectTheFiltersYouNeedToViewTheProductsInYourSelection"
          | translate
      }}
    </span>
  </div>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="slider-row-wrapper">
      <div class="slider-wrapper">
        <p>{{ "NumberOfImages" | translate }}</p>
        <mat-slider
          class="my-slider-2"
          [min]="minimumImages"
          [max]="maxNumberImagesInCatalog"
          step="1"
          [(value)]="maximumImages"
        >
        </mat-slider>
      </div>
      <div class="center">
        <aside id="info-block">
          <section class="file-marker">
            <div class="text-center">
              <div class="box-title">min</div>
              <div class="box-contents">
                <div id="audit-trail">
                  <div class="input-wrapper">
                    <input
                      type="number"
                      pattern="[0-9]*"
                      class="no-border w100"
                      formControlName="minimumImages"
                      [(ngModel)]="minimumImages"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </aside>
      </div>
      <div class="center">
        <div class="separator"></div>
      </div>
      <div class="center">
        <aside id="info-block">
          <section class="file-marker">
            <div class="text-center">
              <div class="box-title">max</div>
              <div class="box-contents">
                <div id="audit-trail">
                  <div class="input-wrapper">
                    <input
                      type="number"
                      pattern="[0-9]*"
                      class="no-border w100"
                      formControlName="maximumImages"
                      [(ngModel)]="maximumImages"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </aside>
      </div>
    </div>
    <div class="slider-row-wrapper">
      <div class="slider-wrapper">
        <p>{{ "NumberOfCatalogs" | translate }}</p>
        <mat-slider
          class="my-slider-2"
          [min]="minimumCatalogs"
          [max]="maxNumberOfCatalogs"
          step="1"
          [(value)]="maximumCatalogs"
        >
        </mat-slider>
      </div>
      <div class="center">
        <aside id="info-block">
          <section class="file-marker">
            <div class="text-center">
              <div class="box-title">min</div>
              <div class="box-contents">
                <div id="audit-trail">
                  <div class="input-wrapper">
                    <input
                      type="number"
                      pattern="[0-9]*"
                      class="no-border w100"
                      formControlName="minimumCatalogs"
                      [(ngModel)]="minimumCatalogs"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </aside>
      </div>
      <div class="center">
        <div class="separator"></div>
      </div>
      <div class="center">
        <aside id="info-block">
          <section class="file-marker">
            <div class="text-center">
              <div class="box-title">max</div>
              <div class="box-contents">
                <div id="audit-trail">
                  <div class="input-wrapper">
                    <input
                      type="number"
                      pattern="[0-9]*"
                      class="no-border w100"
                      formControlName="maximumCatalogs"
                      [(ngModel)]="maximumCatalogs"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </aside>
      </div>
    </div>
    <div class="center gap m16">
      <div class="column w100">
        <span> {{ "ProductCategoriy" | translate }}</span>
        <mat-form-field appearance="fill">
          <mat-select
            formControlName="category"
            placeholder="{{ 'SelectProductCategory' | translate }}"
          >
            <mat-option>{{ "---" | translate }}</mat-option>
            <mat-option
              *ngFor="let category of categoriesOptions"
              [value]="category.name"
              >{{ category.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div class="column w100">
        <span> {{ "ProductBrand" | translate }}</span>
        <mat-form-field appearance="fill">
          <mat-select
            formControlName="brand"
            placeholder="{{ 'SelectProductBrand' | translate }}"
          >
            <mat-option>{{ "---" | translate }}</mat-option>
            <mat-option
              *ngFor="let brand of brandsOptions"
              [value]="brand.name"
              >{{ brand.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="center gap m16">
      <div class="column">
        <span> {{ 'UsedInPlanograms' | translate }}</span>
        <mat-form-field appearance="fill">
          <mat-select
            formControlName="isUsedInPlanogram"
            placeholder="{{ 'All' | translate }}"
          >
            <mat-option>{{ "All" | translate }}</mat-option>
            <mat-option [value]="true">{{ "Yes" | translate }}</mat-option>
            <mat-option [value]="false">{{ "No" | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="column">
        <span> {{ 'ProductStatus' | translate }}</span>
        <mat-form-field appearance="fill">
          <mat-select
            formControlName="productStatus"
            placeholder="{{ 'All' | translate }}"
          >
            <mat-option>{{ "All" | translate }}</mat-option>
            <mat-option [value]="true">{{ "Active" | translate }}</mat-option>
            <mat-option [value]="false">{{
              "Inactive" | translate
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="column">
        <span> {{ "UpdatedAt" | translate }}</span>
        <mat-form-field appearance="fill">
          <mat-date-range-input [rangePicker]="picker">
            <input
              matStartDate
              [placeholder]="'StartDate' | translate"
              formControlName="updatedAtStart"
              #dateRangeStart
              (dateChange)="
                dateRangeChange($event, dateRangeStart, dateRangeEnd)
              "
            />
            <input
              matEndDate
              [placeholder]="'EndDate' | translate"
              formControlName="updatedAtEnd"
              #dateRangeEnd
              (dateChange)="
                dateRangeChange($event, dateRangeStart, dateRangeEnd)
              "
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </div>
    <div style="display: flex; place-content: space-between; gap: 8px">
      <button
        class="w100"
        style="color: rgba(var(--primary-color), 1)"
        mat-stroked-button
        type="reset"
        (click)="Clear"
      >
        {{ "Clear" | translate }}
      </button>
      <button
        class="w100"
        style="background-color: rgba(var(--primary-color), 1); color: white"
        mat-button
        type="submit"
        [disabled]="!form.valid"
      >
        {{ "ApplyFilters" | translate }}
      </button>
    </div>
  </form>
</div>
