<div class="wrapper-main">
  <div class="main-container" style="height: 800px;">
    <div class="table-title-container">
      <div class="table-title">
        {{'Equipments'|translate}}
      </div>
      <div class="table-sub-title">
        {{'EquipmentsList'|translate}}
      </div>
    </div>
    <br />

    <div class="sub-container">
      <div class="border form-container-add">
        <span class="form-title">{{'EquipmentInfo'|translate}}</span>
        <form [formGroup]="equipmentForm">
          <mat-form-field style="width:100%">
            <input matInput [(ngModel)]="equipment.name" placeholder="{{'Name'|translate}}" formControlName="nameCtrl"
              required>
            <mat-error *ngIf="equipmentForm.controls['nameCtrl'].hasError('required')">
              {{'RequiredField'|translate}}
            </mat-error>
            <mat-error
              *ngIf="equipmentForm.controls['nameCtrl'].hasError('unique')&&!equipmentForm.controls['nameCtrl'].hasError('required')">
              {{'NameAllreadyExist'|translate}}
            </mat-error>
          </mat-form-field>
          <mat-checkbox formControlName="isVerticalCtrl"
            [(ngModel)]="equipment.isVertical">{{'IsVertical'|translate}}</mat-checkbox>
          <mat-form-field style="width:100%">
            <input type="number" min=1 matInput [(ngModel)]="equipment.width"
              placeholder="{{getEquipmentSizePlaceHolder()|translate}}({{'CentimeterAb'|translate}})" formControlName="widthCtrl" required>
            <mat-error *ngIf="equipmentForm.controls['widthCtrl'].hasError('required')">
              {{'RequiredField'|translate}}
            </mat-error>
            <mat-error
              *ngIf="equipmentForm.controls['widthCtrl'].hasError('min')&&!equipmentForm.controls['widthCtrl'].hasError('required')">
              {{'MinimumValue'|translate}}
            </mat-error>
          </mat-form-field>
          <mat-form-field style="width:100%">
            <input type="color" [(ngModel)]="equipment.backgroundColor" matInput
              placeholder="{{'BackgroundColor'|translate}}" formControlName="backgroundColorCtrl">
          </mat-form-field>

          <div class="row row-space-between" style="padding: 0px 12px;">
            <span class="form-title">{{'ShelvesInfo'|translate}}</span>
            <button mat-flat-button color="primary" (click)="addShelf()" style="float:right; background-color: rgba(var(--primary-color), 0.23); color: var(--primary-color);">
              <mat-icon>add</mat-icon>
              {{'AddShelf'|translate}}
            </button>
          </div>
          <!-- equipmentForm.get('shelves').controls -->
          <div class="form-group" formArrayName="shelves" *ngFor="let shelf of equipment.shelves; let i = index">
            <div [formGroupName]="i">
              <mat-accordion>
                <mat-expansion-panel [expanded]="step === i" (opened)="setStep(i)">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      {{'Shelf'|translate}} #{{i+1}}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <mat-form-field style="width:100%">
                    <input type="number" min=1 matInput [(ngModel)]="shelf.shelfHeight" (ngModelChange)="onChangeShelfHeight(shelf)"
                      placeholder="{{getShelfSizePlaceHolder()|translate}}({{'CentimeterAb'|translate}})" formControlName="heightCtrl" required>
                    <mat-error *ngIf="equipmentForm.get('shelves').controls[i].controls['heightCtrl'].hasError('required')">
                      {{'RequiredField'|translate}}
                    </mat-error>
                    <mat-error
                      *ngIf="equipmentForm.get('shelves').controls[i].controls['heightCtrl'].hasError('min')&&!equipmentForm.get('shelves').controls[i].controls['heightCtrl'].hasError('required')">
                      {{'MinimumValue'|translate}}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field style="width:100%">
                    <input type="number" min=1 matInput [(ngModel)]="shelf.depth" placeholder="{{'Depth'|translate}}({{'CentimeterAb'|translate}})"
                      formControlName="depthCtrl">
                    <mat-error *ngIf="equipmentForm.get('shelves').controls[i].controls['depthCtrl'].hasError('min')">
                      {{'MinimumValue'|translate}}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field style="width:100%">
                    <input type="number" [(ngModel)]="shelf.shelfSeparatorHeight" matInput (ngModelChange)="onChangeShelfHeight(shelf)"
                      placeholder="{{getSeparatorSizePlaceHolder()|translate}}({{'CentimeterAb'|translate}})" formControlName="shelfSeparatorHeightCtrl"
                      min=1>
                    <mat-error *ngIf="equipmentForm.get('shelves').controls[i].controls['shelfSeparatorHeightCtrl'].hasError('min')">
                      {{'MinimumValue'|translate}}
                    </mat-error>
                  </mat-form-field>

                  <button mat-flat-button color="primary" (click)="removeShelf(i)" style="float:right; background-color: #A2333329; color: #A23333;">
                    <mat-icon>delete</mat-icon>
                    {{'RemoveShelf'|translate}}
                  </button>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
        </form>

      </div>
      <div class="border form-container-preview">
        <span class="form-title">
          {{'EquipmentPreview'|translate}}
        </span>
        <div class="preview-vertical" *ngIf="equipment.isVertical">
          <div *ngFor="let item of [].constructor(equipment.shelves.length); let i = index">
            <svg
              attr.viewBox="0 0 {{equipment.shelves[i].shelfHeight + equipment.shelves[i].shelfSeparatorHeight}} {{equipment.width}}"
              [ngStyle]="{'background': equipment.backgroundColor}" style="border:solid 1px; max-height: 300px; width: 100%; height: 100%;">
              <rect
                [attr.x]="equipment.shelves[i].shelfHeight" [attr.height]="equipment.width"
                [attr.width]="equipment.shelves[i].shelfSeparatorHeight?equipment.shelves[i].shelfSeparatorHeight: '0.5'" />
            </svg>
          </div>
        </div>

        <div class="preview-horizontal" *ngIf="!equipment.isVertical">
          <svg *ngFor="let item of [].constructor(equipment.shelves.length); let i = index"
            attr.viewBox="0 0 {{equipment.width}} {{equipment.shelves[i].shelfHeight + equipment.shelves[i].shelfSeparatorHeight}}"
            [ngStyle]="{'background': equipment.backgroundColor}" style="border:solid 1px">
            <rect 
              [attr.y]="equipment.shelves[i].shelfHeight" [attr.width]="equipment.width"
              [attr.height]="equipment.shelves[i].shelfSeparatorHeight?equipment.shelves[i].shelfSeparatorHeight: '0.5'" />
          </svg>
        </div>
        <div class="row row-space-between equip-info">
          <div class="col" style="padding: 0px 20px 0px 0px;">
            <span>{{'EquipmentHeight'|translate}}</span><br>
            <span *ngIf="!equipment.isVertical" style="font-weight: 500;">{{getEquipmentHeight()}}cm</span>
            <span *ngIf="equipment.isVertical" style="font-weight: 500;">{{equipment.width}}cm</span>
          </div>
          <div class="col" style="padding: 0px 0px 0px 20px;">
            <span>{{'EquipmentWidth'|translate}}</span><br>
            <span *ngIf="equipment.isVertical" style="font-weight: 500;">{{getEquipmentHeight()}}cm</span>
            <span *ngIf="!equipment.isVertical" style="font-weight: 500;">{{equipment.width}}cm</span>
          </div>
        </div>
        <div class="row row-space-between">
          <div class="col" style="padding: 0px 20px 0px 0px;">
            <span>{{'NumberOfShelves'|translate}}</span><br>
            <span style="font-weight: 500;">{{equipment.shelves.length}}</span>
          </div>
        </div>
        <button [disabled]="!equipment.id" mat-button mat-flat-button color="primary" style="width: 100%; background-color: #A2333329; color: #A23333;" (click)="deleteEquipment()">
          <mat-icon>delete</mat-icon>
          {{'RemoveEquipment'|translate}}
        </button>
      </div>
    </div>
  </div>
  <div class="buttons-container">
    <button mat-stroked-button color="primary" (click)="onClose()" style="float:right">
      <mat-icon>arrow_back</mat-icon>
      {{'Back'|translate}}
    </button>
    <button mat-flat-button color="primary" (click)="onSave()" style="float:right">
      {{'Save&Continue'|translate}}
      <mat-icon>arrow_forward</mat-icon>
    </button>

  </div>
</div>